/* ================================= Dashboard Fluid Css Start =========================== */
.dashboard-fluid {
    .dashboard {
        position: relative;
        &__inner {
            display: flex;
            flex-wrap: wrap;

        } 
        &__right {
            width: calc(100% - 340px);
            @include lg-screen {
                width: 100%;
            }
        }
    }
    /* ======================= Dashboard Header Start======================= */
    .dropdown {
        display: inline-block !important;
        @include sm-screen {
        }
    }
    .dashboard-header {
        padding: 0 60px;
        background: var(--base-gradient);
        @media (max-width: 1699px) {
            padding: 0 20px;
       }
        &__inner {
            padding: 15px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }
        &__left {
            &-title {
                color: hsl(var(--white));
                margin-bottom: 0;
                font-size: 24px;
                font-family: var(--heading-font);
                font-weight: 600;
            }
        }
    }
    // Header Left
  
    // header Right
    .user-info {
        position: relative;
        text-align: center;
        &__content {
            margin-left: 15px;
            text-align: left;
        }
        .user-info-dropdown {
            border-radius: 4px;
            overflow: hidden;
            transition: 0.25s linear;
            background-color: hsl(var(--white));
            width: 200px;
            position: absolute;
            right: 0;
            z-index: 9;
            top: 100%;
            margin-top: 18px;
            transform: scale(.95);
            visibility: hidden;
            opacity: 0;
            &.show {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
            @include md-screen {
                transform: unset !important;
                top: 43px !important;
            }
            &__item{
                &:last-child {
                    .dropdown-item {
                        border-bottom: 0 !important;
                    }
                }
            }
            &__link {
                border-bottom: 1px dashed hsl(var(--dark)/.1) !important;
                padding: 8px 16px !important;
                color: hsl(var(--dark)) !important;
                margin-left: 0 !important;
                width: 100%;
                text-align: left;
                &:active {
                    background-color: hsl(var(--base));
                }
                &:hover {
                    background-color: hsl(var(--base));
                    color: hsl(var(--white)) !important;
                }
            }
        }
        &__link {
            font-family: var(--body-font);
            font-weight: 700;
            color: hsl(var(--white));
        }
        &__info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &__thumb {
            width: 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;
            cursor: pointer;
            img {
                @extend .fit-image; 
            }
        }

        &__name {
            color: hsl(var(--white));
            @extend .fs-15; 
            display: block;
            font-weight: 400;
            font-family: var(--heading-font);
        }
    }
    /* ======================= Dashboard Header End======================= */
    
    /* ========== Dashboard Body Start =============== */
    .dashboard-body {
        position: relative;
        padding: 30px 20px;
        @include lg-screen {
            padding: 25px 15px;
        }
        &__bar {
            &-icon {
                color: hsl(var(--white));
                font-size: 1.5625rem; //25px
                margin-bottom: 10px;
                cursor: pointer;
                width: 50px;
                height: 40px;
                line-height: 40px;
                background-color: hsl(var(--base));
                text-align: center;
                border-radius: 5px;
            }
        }
    }
    // Dashboard Card
    .dashboard-card {
        padding: 25px 30px;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: hsl(var(--section-bg));
        @include sm-screen {
            padding: 15px;
        } 
        &__shape {
            -webkit-mask-image: url(../images/shapes/dashboard-shape1.png);
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            background: var(--base-gradient);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            z-index: -1;
            top: 0;
        }
        &__header {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            @include msm-screen {
                margin-bottom: 25px;
            }
            &-icon {
                background: var(--dashboard);
                width: 50px;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                color: hsl(var(--white));
                border-radius: 110% 150% 100% 120%/100% 170% 90% 130%;
                &.style-one {
                    background: var(--dashboard-one);
                }
                &.style-two {
                    background: var(--dashboard-two);
                }
                &.style-three {
                    background: var(--dashboard-three);
                }
                &.style-four {
                    background: var(--dashboard-four);
                }
                &.style-five {
                    background: var(--dashboard-five);
                }
            }
            &-title {
                margin-bottom: 0;
                @extend .fs-16;
                font-weight: 400;
                font-family: var(--body-font);
                color: hsl(var(--white)/.8);
            }
            &-currency {
                font-size: 20px;
                font-weight: 700;
                font-family: var(--heading-font);
                color: hsl(var(--white));
            }
            &-content {
                width: calc( 100% - 50px);
                padding-left: 20px;
            }
        }
        &__item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;
            @include sm-screen {
                gap: 6px;
            }
        }
        &__content {
          border: 1px solid hsl(var(--white)/.09);
          padding: 15px;
          border-radius: 5px;
          width: calc( 33% - 10px);
          @media (max-width:1699px) {
            padding: 10px;
          }
          @include xxxl-screen {
            padding: 8px;
          }
          @include xxl-screen {
             padding: 6px;
          }
          @include sm-screen {
            padding: 5px;
            width: calc( 33% - 6px);
          }
          @include msm-screen {
            padding: 7px;
          }
        }
        &__text {
            font-weight: 600;
            font-family: var(--body-font);
            font-size: 12px;
            color: hsl(var(--white)/.8);
        } 
        &__amount {
            margin-bottom: 0;
            font-size: 16px;
            @include xxxl-screen {
                font-size: 14px;
            }
            @include sm-screen {
                font-size: 13px;
            }
            @include msm-screen {
                font-size: 14px;
            }
        }  
        &__icon {
            font-size: 2.5rem; //40px
            width: 60px;
            height: 60px;
            color: hsl(var(--base));
            background-color: hsl(var(--body-background));
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid hsl(var(--base));
            position: relative;
            margin: 5px;
            animation: swing 1.5s linear infinite;
            @include sm-screen {
                font-size: 1.5625rem; // 25px
                width: 50px;
                height: 50px;
            }
        }
    }
   /* ======================= apexcart css start here ======================= */
    #chart {
        max-width: 400px;
        @include lg-screen {
            max-width: 450px;
        }
        @include sm-screen {
            max-width: 400px;
        }
        @include xsm-screen {
            max-width: 300px;
        }
      }      
      .details{
        background-color: hsl(var(--section-bg));
        border-radius: 5px;
        padding: 20px;
        height: 100%;
        max-width: 800px;
        @include lg-screen {
            max-width: 100%;
        }
        &__list{
          &-item {
            margin-bottom: 10px;
            &-color {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 20px;
                &.one {
                    background-color: hsl(210, 92%, 37%);
                }
                &.two {
                    background-color: hsl(53, 90%, 46%);
                }
                &.three {
                    background-color: hsl(350, 95%, 64%);
                }
                &.four {
                    background-color:hsl(288, 100%, 52%);
                }
                &.five {
                    background-color: hsl(262, 84%, 43%);
                }
                &.six {
                    background-color: hsl(191, 100%, 50%);
                }
                &.seven {
                    background-color: hsl(207, 100%, 47%);
                }
                &.eight {
                    background-color: hsl(181, 96%, 39%);
                }
            }
          }
        }
      }   
      .total-profit {
        background-color: hsl(var(--section-bg));
        padding: 20px;
        border-radius: 5px;
      }
      .invest {
        .select {
            background: var(--base-gradient);
            border-radius: 5px;
            padding: 5px 10px;
            color: hsl(var(--white));
        }
      }
      .time {
        margin-left: 10px;
        .select {
            background: var(--base-gradient);
            border-radius: 5px;
            padding: 5px 10px;
            color: hsl(var(--white));
        }
      }
    //============= apexchart end here ===============
    //========= dashboard profit css start here =========
     .dashboard-item {
        background-color: hsl(var(--section-bg));
        padding: 20px 30px;
        border-radius: 8px;
        height: 100%;
        &__title {
            margin-bottom: 40px;
            @include md-screen {
                margin-bottom: 20px;
            }
        }
        &__investment {
            text-align: center;
        }
        &__wallet {
            background-color: hsl(var(--white)/.05);
            padding: 15px;
            border-radius: 8px;
            width: 50%;
            @include xxsm-screen {
                padding: 10px;
            }
            &-title {
                font-size: 14px;
            }
            &.two {
                margin-left: 10px;
            }
            &-number {
                margin-bottom: 5px;
                @include xxxl-screen {
                    font-size: 16px;
                }
            }
            &-persentage {
                color: hsl(142, 61%, 59%);
            }
        }
        &__pay {
            margin-bottom: 20px;
            &-text {
                font-size: 14px;
            }
            &-number {
                font-size: 18px;
                font-family: var(--heading-font);
                font-weight: 700;
                color: hsl(var(--white));
            }
        }
        &__desc {
            margin-top: 50px;
            font-size: 16px;
            border-top: 1px solid hsl(var(--white)/.1);
            padding-top: 30px;
        }
        .price {
            font-size: 14px;
            font-weight: 500;
        }
     }
    /* ========== dashboard profit css end here ========== */
   /* ==============progress bar css ============== */
    .progress-basic .progress-bar {
        transition: width 0.6s ease;
        height: 5px;
      }
      .progress-basic-1 {
        width: 60%;
        background-color: hsl(207, 100%, 47%);
      }
      .progress-basic-2 {
        width: 80%;
        background-color: hsl(288, 100%, 52%);
      }
      .progress-basic-3 {
        width: 70%;
        background-color: hsl(191, 100%, 50%);
      }
      .progress-basic .progress {
        background-color: #eeedf3;
        border-radius: 10px;
        height: 5px;
      }
      .progress-labels .progress {
        background-color: #eeedf3;
        border-radius: 10px;
        height: 25px;
      }
      .progress-labels .progress-bar {
        background-color:hsl(var(--white));
        height: 25px;
        font-size: 15px;
      }
    .investment-wrapper {
      margin-bottom: 10px;
      @extend .flex-between;
     &__title {
        margin-bottom: 0px;
     }
     &__interest {
        font-size: 12px;
        font-weight: 700;
     }
     &__date {
        font-size: 12px;
     }
     &__icon {
        width: 35px;
        height: 35px;
        background-color: hsl(207, 100%, 47%);
        color: hsl(var(--white));
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        &.style-two {
            background-color: hsl(288, 100%, 52%);
        }
        &.style-three {
            background-color: hsl(191, 100%, 50%);
        }
     }
    &__rate {
        width: calc(100% - 35px);
        padding-left: 10px;
    }
}

 // ====================== Dashboard Right End========================     
}
#timeline-chart .apexcharts-toolbar {
    opacity: 1;
    border: 0;
  }
/* ================================= Dashboard Fluid Css End =========================== */
