/* ==========================investor css start here ========================== */
.investor-item {
    position: relative;
    z-index: 1;
    background-color: hsl(var(--section-bg));
    padding: 40px 60px;
    text-align: center;
    border-radius: 7px;
    transition: .2s linear;
    @include xl-screen {
        padding: 35px 40px;
    }
    @include lg-screen {
        padding: 30px;
    }
    @include msm-screen {
        padding: 25px;
    }
    &:hover {
        transform: translateY(-5px);
        .investor-item{
            &__number {
                color: hsl(var(--white)/.5) !important;
            }
        }
    }

    &__number {
        color: hsl(var(--white)/.05);
        position: absolute;
        font-size: 58px;
        font-family: var(--heading-font);
        font-weight: 700;
        top: -10px;
        left: 10px;
        z-index: -1;
        transition: .3s linear;
        @include xl-screen {
            font-size: 45px;
            top: 0;
        }
        @include lg-screen {
            top: 0px;
            font-size: 40px;
            left: 5px;
        }
    }
    &__title {
        margin-bottom: 0px;
        font-weight: 600;
        color: hsl(var(--white)/.8);
        letter-spacing: 2px;
    }
    &__usd {
        font-size: 40px;
        background: var(--base-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0;
        font-weight: 700;
        font-family: var(--heading-font);
        @include lg-screen {
            font-size: 32px;
        }
        @include msm-screen {
            font-size: 30px;
        }
    }
}
/* ===========================investor css end here=========================== */