/* ================================= Form Css Start =========================== */
.form--label {
    margin-bottom: 6px;
    @extend .fs-16; 
    color: hsl(var(--white) / .6);
    font-weight: 400;
}

.form-group {
    margin-bottom: 1rem;
}

.form--control {
    border-radius: 5px;
    font-weight: 400;
    outline: none;
    width: 100%;
    padding: 13px 15px;
    font-family: var(--body-font);
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid hsl(var(--white) / .1);
    color: hsl(var(--white)); 
    line-height: 1;
    @include xxsm-screen {
        padding: 8px 10px;
    }
    &::placeholder {
        color: hsl(var(--white) / .6);
        @extend .fs-16; 
        @include lg-screen {
            font-size: 14px;
        }
        @include xsm-screen {
            font-size: 13px;
        }
    }
    &:focus {
        border-radius: 3px;
        color: hsl(var(--white)); 
        box-shadow: none;
        border-color: hsl(var(--base)); 
        background-color: transparent; 
    }
    &:disabled, &[readonly] {
        background-color: hsl(var(--base)/.3); 
        opacity: .6;
        border: 1px solid hsl(var(--body-color)/.1);
    }
    &[type="password"] {
        color: hsl(var(--white)/.5);
        &:focus {
            color: hsl(var(--white)); 
        }
    }
    &[type="file"] {
        line-height: 50px;
        padding: 0;
        position: relative;
        &::file-selector-button {
            border: 1px solid hsl(var(--black) / .08);
            padding: 4px 6px;
            border-radius: .2em;
            background-color: hsl(var(--base)) !important;
            transition: .2s linear;
            line-height: 25px;
            position: relative;
            margin-left: 15px;
            color: hsl(var(--body-color)) !important;
            &:hover {
                background-color: hsl(var(--base));
                border: 1px solid hsl(var(--base));
                color: hsl(var(--white));
            }
        }
    }
}
textarea.form--control {
    height: 150px;
    padding: 15px;
}
// Autofill Css
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: hsl(var(--white)) !important;
}

// input gruop
.input--group {
    position: relative;
}
.form-group {
    position: relative;
    margin-bottom: 16px;
}
.form--check.form-group {
    height: 16px;
}
// Show Hide Password
.password-show-hide {
    position: absolute;
    right: 20px;
    z-index: 3;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    color: hsl(var(--white)/.5);
}
/* --------------- Number Arrow None --------------------- */
#send-amount input[type='file'] {
    color: hsl(var(--base)); 
    cursor: pointer;
}
input #send-amount::file-selector-button { 
    display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=number]{
    -moz-appearance: textfield;
}
/* ============== Select here ================== */

// Form Select  
.select {
    color: hsl(var(--white) / .6);
    border: 0;
    position: relative;
    &:focus {
        border: 0;
    }
    option {
        background-color: hsl(var(--dark));
        color: hsl(var(--white));
    }
}

/* Custom Checkbox Design */
.form--check {
    display: flex;
    flex-wrap: wrap;
    a {
        display: inline;
    }
    .form-check-input {
        box-shadow: none;
        background-color: transparent;
        box-shadow: none !important;
        border: 0;
        position: relative;
        border-radius: 2px;
        width: 14px;
        height: 14px;
        border: 1px solid hsl(var(--white));
        &:checked {
            background-color: hsl(var(--base)) !important;
            border-color: hsl(var(--base)) !important;
            box-shadow: none;
            &[type=checkbox] {
                background-image:none ;
                }
            &::before {
                position: absolute;
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                color: hsl(var(--white)); 
                @extend .fs-11; 
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .form-check-label {
        width: calc(100% - 14px);
        padding-left: 8px;
        font-weight: 400;
        font-family: var(--body-font);
    }
    label {
        @include xsm-screen {
            @include fs-15; 
        }
    }
    a {
        @include xsm-screen {
            @include fs-15; 
        }
    }
}
/* Custom Radion Design */
.form--radio {
    .form-check-input {
        box-shadow: none;
        border: 2px solid hsl(var(--base));
        position: relative;
        margin-right: 10px;
        &:active {
            filter: brightness(100%);
        }
        &:checked {
            background-color: transparent;
            border-color: hsl(var(--base));
            &[type=radio] {
                background-image:none ;
                }
            &::before {
                position: absolute;
                content: "";
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                width: 7px;
                height: 7px;
                background-color: hsl(var(--base));
                border-radius: 50%;
                z-index: 999;
            }
        } 
    }
}
/*  Custom Switch Design */
.form--switch {
	.form-check-input {
		width: unset;
		border-radius: 3px;
		background-image: none;
		position: relative;
		box-shadow: none;
		border: 0;
		background-color: hsl(var(--white)) !important;
		padding: 10px 20px !important;
		margin-left: 0;
		margin-bottom: 5px;
        border-radius: 40px;
        max-width: 0;
		&:focus {
			width: unset;
			border-radius: 40px;
			background-image: none;
			position: relative;
			box-shadow: none;
			border: 0;
		}
		&::before {
			position: absolute;
			content: "";
			width: 10px;
			height: 10px;
			background-color: hsl(var(--dark)/.6);
			top: 50%;
			transform: translateY(-50%);
			border-radius: 2px;
			left: 5px;
            border-radius: 50%;
			transition: .2s linear;
		}
		&:checked {
			background-color: hsl(var(--base)); 
            &[type=checkbox] {
                background-image: none;
            }
			&::before {
				left: calc(100% - 15px);
                background-color: hsl(var(--base));
			}
		}
	}
}
.form-check.form--switch {
	display: flex;
	flex-wrap: wrap;
	.form-check-label {
		margin-right: 10px;
		color: hsl(var(--white));
		font-weight: 500;
		margin-bottom: 0;
		line-height: 2;
	}
}
/*  Custom Switch End Design */
/* ----------------------------- Calander Icon -------------- */
::-webkit-calendar-picker-indicator {
    filter: invert(.5);
  }
  ::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
/* ================================= Form Css End =========================== */