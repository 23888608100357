/* ==============Contact top Start============== */
.contact {
    &__bg {
        position: relative;
        background-color: hsl(var(--section-bg));
        border-radius: 60px;
        @include md-screen {
            border-radius: 10px;
        }
        &::before {
            position: absolute;
            content: "";
            top: 5px;
            left: -30px;
            width: 100%;
            height: 90%;
            transform: rotate(183deg);
            background: var(--background-gradient);
            z-index: -1;
            border-radius: inherit;
            @include md-screen {
                display: none;
            }
        }
    }
    &__title {
        margin-bottom: 20px;
        font-size: 30px;
        @include sm-screen {
            font-size: 28px;
        }
    }
    &__desc {
        margin-bottom: 25px;
    }
    &__shape {
        background: var(--base-gradient);
        width: 100%;
        height: 100%;
        -webkit-mask-image: url(../images/shapes/account-shape.png);
        mask-image: url(../images/shapes/account-shape.png);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-size: cover;
        mask-size: cover;
        position: absolute;
        bottom: 30px;
        left: -30px;
        z-index: -1;
    }
}
.contact-item {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    &__icon {
        width: 110px;
        height: 100px;
        display: flex;
        display: flex;
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
        background: var(--background-gradient);
        justify-content: center;
        align-items: center;
        color: hsl(var(--white));
        @include fs-25; 
        @include sm-screen {
            width: 70px;
            height: 70px;
            @include fs-18;
        }
    }   
    &__content {
        width: calc(100% - 110px);
        padding-left: 20px;
        @include sm-screen {
            width: calc(100% - 70px);
            padding-left: 15px;
        }
    }
    &__title {
        margin-bottom: 10px;
        color: hsl(var(--white));
    }
    &__desc {
        color: hsl(var(--white)/.6);
    }
}
/*=============== Contact top End ===============*/


/*===================== Contact Bottom Start===================== */
.contact-map {
    width: 100%;
    min-height: 300px;
    height: 100%;
    padding: 30px;
    iframe {
        width: 100%;
        height: 100%;
        border-radius: 20px;

    }
}

.contactus-form {
    // box-shadow: var(--box-shadow);
    // background-color: hsl(var(--white)/.1);
    padding: 40px 30px;
    @include sm-screen {
        padding: 30px 20px;
    }
    @include msm-screen {
        padding: 20px 15px;
    }
}
/* =====================Contact Bottom End =====================*/

