/* ======================commition css start here ====================== */
.commition-section{
    position: relative;
    z-index: 1;
    &__inner{
        &-bg {
            -webkit-mask-image: url(../images/shapes/commition.png);
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            background: var(--base-gradient);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            z-index: -1;
            top: 50px;
            @include lg-screen {
                display: none;
            }
        }
    }
    &__shape {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -9;
        width: 100%;
        height: 100%;
        @include lg-screen {
            display: none;
        }
    }
}
.commition {
    position: relative;
    z-index: 2;
    &__title {
        font-size: var(--heading-one);
    }
   &__thumb {
    margin-left: -100px;
    margin-top: 110px;
    height: 100%;
    position: relative;
    z-index: 2;
    @include lg-screen {
        margin-left: 0;
        margin-top: 0;
    }
    img {
        @extend .fit-image;
    }
   }
   &__desc {
    margin-bottom: 30px;
    font-family: var(--body-font);
    font-weight: 400;
    color: hsl(var(--white));
    @extend .fs-18;
   }
}
/* ======================commition css end here ====================== */