//================================= blog details css start here =================================
.blog-details {
    border-radius: 8px;
    overflow: hidden;
    background-color: hsl(var(--section-bg));
    // box-shadow: 0px 2px 15px hsl(var(--white) /.25);
    &__thumb {
        height: 450px;
        max-height: 100%;
        @include md-screen {
            height: 350px;
        }
        @include sm-screen {
            height: 300px;
        }
        img {
            @extend .fit-image; 
        }
    }
    &__content {
        padding: 35px 25px;
        @include sm-screen {
            padding: 30px 20px;
        }
        @include msm-screen {
            padding: 25px 15px;
        }
    }
    &__title {
        margin-top: 20px;
        margin-bottom: 15px;
    }
    &__desc {
        margin-bottom: 15px;
    }
}
// tag start
.tag {
    @extend .flex-between;
    border-top: 1px solid hsl(var(--white) /.1);
    padding: 20px 40px;
    @include sm-screen {
        padding: 20px 30px;
    }
    @include msm-screen {
        padding: 15px;
    }
    &__title{
       @extend .fs-16;
       margin-bottom: 0;
       font-weight: 400;
       span {
        font-weight: 700;
       }
    }

}
/* tag end */

/* investment system css start here */
.investment-system{
    &__item {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: hsl(var(--white) /.8);
      padding-left: 30px;
      margin-bottom: 20px;
      &::before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--background-gradient);
        left: 0px;
        bottom: 6px;
        line-height: 15px;
      }
    }
}
/*============= contact form css start here============= */
.contact-form {
    margin-top: 50px;
    padding: 30px;
    border-radius: 8px;
    background-color: hsl(var(--section-bg));
}
/* contact form css end here */
// investment system css end here 
.social-share {
    &__title {
        @extend .fs-16;
    }
}
/* ================================== blog details css end here ================================== */


