/*================== investment css start here================== */
.investment-section{
    position: relative;
    background-repeat: inherit !important;
    background-size: unset !important;
    overflow: hidden;
}
.shape-one {
  position: absolute;
  content: "";
  top: -170px;
  left: 100px;
  z-index: 1;
  animation: horizontal-move 3s ease-in-out infinite;
  animation-delay: 1s;
  @include md-screen {
    display: none;
  }
}
.shape-two {
    position: absolute;
    content: "";
    top: -350px;
    right: 100px;
    z-index: 1;
    animation: horizontal-move 4s ease-in-out infinite;
    animation-delay: 1s;
    @include md-screen {
        display: none;
    }
  }
  @keyframes horizontal-move {
    0% {
      transform: rotateZ(5deg);
    }
  
    50% {
      transform: rotateZ(-5deg);
    }
  
    100% {
      transform: rotateZ(5deg);
    }
  }
/*  ============== plan card css ==================*/
.investment-inner {
    @extend .flex-between;
    @include md-screen {
        display: block;
    }
}
.plan-card-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 35px;
    border-radius: 10px;
    width: 50%;
    z-index: 2;
    @include md-screen {
        width: 100%;
    }
    @include msm-screen {
        padding: 25px;
    }
    @include xsm-screen {
        padding: 20px;
    }
}
.title {
    margin-bottom: 0;
 }
 .subtitle {
    font-weight: 500;
 }
.plan-list{
    &__item {
        @extend .flex-between;
        @extend .fs-18;
        padding: 10px 15px;
        color: hsl(var(--white));
        background-color: hsl(var(--white)/.1);
        border-radius: 3px;
        margin-top: 5px;
        font-family: var(--heading-font);
        font-weight: 500;
    }
}
.plan-card-select {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: -30px;
    .title {
       font-size: 32px;
       @include sm-screen {
        font-size: 24px;
       }
    }
    .select {
        color: hsl(var(--white));
        background-color: hsl(var(--white)/.2) !important;
        @extend .fs-14; 
        font-weight: 400;
        border-radius: 30px;
        height: 25px;
        border: none;  
        padding: 0 10px;    
        option {
            background-color: hsl(var(--dark));
        }
    }
}
/* =========================plan card end here======================= */

/* ===============calculator start here=============== */

.calculator {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 5px;
    padding: 30px 35px;
    width: 50%;
    z-index: 50;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include lg-screen {
        padding: 20px 30px;
    }
    @include md-screen {
        width: 100%;
        border-radius: 5px;
    }
    @include xsm-screen {
        padding: 15px 20px ;
    }
    &__title{
        text-align: center;
        font-weight: 700;
    }
}
.position-relative {
    .form--control {
        @include xxsm-screen {
           padding: 5px;
        }
    }
}
.cal-area {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &__icon {
    position: absolute;
    width: 35px;
    height: 35px;
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    transform: translateY(-50%);
    top: 50%;
    right: 5px;
    background-color: hsl(var(--white)/.09);
    line-height: 35px;
    @include lg-screen {
        width: 30px;
        height: 30px;
        right: 3px;
    }
    @include xsm-screen {
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        right: 2px;
    }
    }
}
.input-wrap {
    position: relative;
    width: 45%;
    label {
        margin-bottom: 15px;
        font-size: 16px;
        font-family: var(--heading-font);
        font-weight: 500;
        color: hsl(var(--white));
    }
    .user-wallets {
        margin-bottom: 15px;
        color: hsl(var(--white));
        background-color:transparent;
        padding: 0;
        @extend .fs-14; 
        font-weight: 400;
        border-color: none;
        border: none;
        option {
            background-color: hsl(var(--dark));
            color: hsl(var(--white));
        }
    }
}
.profit-result {
    background: var(--profit-gradient);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    width: 50%;
    border: 1px solid hsl(var(--white)/.07);
}
.profit-value {
    margin-bottom: 0;
}
.cal-bottom-area {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
}
.profit-cal {
    width: 50%;
    &__button {
        margin-top: 20px;
    }
}
/* ===============calculator end here=============== */