/* ==============plan-section css ============= */
.plan-item {
    text-align: center;
    border-radius: 10px;
    position: relative;
    background-color: hsl(var(--section-bg));
    height: 100%;
    transition: all ease .2s;
    overflow: hidden;
    &:hover {
        transform: scale(1.03);
    }
    &__body {
        padding: 20px 20px 50px 20px;
       
        @include xl-screen {
            padding: 20px 10px 50px 10px;
        }
        @include lg-screen {
            padding: 20px 12px 50px 12px;
        }
        @include sm-screen {
            padding: 20px 15px 30px 15px;
        }
        @include msm-screen {
            padding: 20px 10px 30px 10px;
        }
        @include xsm-screen {
            padding: 20px 20px 30px 20px;
        }
    }
    &__header {
        padding: 27px;
        border-radius: 10px;
        -webkit-mask-image: url(../images/shapes/plan-shape.png);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        background: var(--background-gradient);
        width: 100%;
        @include msm-screen {
            padding: 20px;
        }
    }
    &__title {
        margin-bottom: 0;
        font-size: 58px;
        font-family: var(--heading-font);
        font-weight: 700;
        color: hsl(var(--white));
        @include md-screen {
            font-size: 45px;
        }
        @include msm-screen {
            font-size: 30px;
        }
    }
    &__name {
        margin-bottom: 0;
        font-weight: 600;
        letter-spacing: 1px;
    }
    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 30px;
        &::before {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: .5px;
            height: 25px;
            background-color: hsl(var(--white)/.2);
        }
    }
    &__time {
        margin-bottom: 0;
        font-weight: 600;
        @include lg-screen {
            font-size: 18px;
        }
        @include md-screen {
            font-size: 20px;
        }
        @include sm-screen {
            font-size: 17px;
        }
        @include msm-screen {
            font-size: 16px;
        }
        @include xsm-screen {
            font-size: 20px;
        }
    }
    &__amount {
        font-size: 16px;
        color: hsl(var(--white));
        font-weight: 400;
        @include lg-screen {
            font-size: 14px;
        }
        @include md-screen {
            font-size: 15px;
        }
        @include sm-screen {
            font-size: 14px;
        }
        @include msm-screen {
            font-size: 13px;
        }
        @include xsm-screen {
            font-size: 15px;
        }
    }
    &__list {
        margin-bottom:30px;
        padding-top: 10px;
        border-top: .5px solid hsl(var(--base)/.6);
        @include sm-screen {
            margin-bottom: 20px;
        }
        &-inner {
            @extend .fs-16;
            font-weight: 400;
            font-family: var(--body-font);
            color: hsl(var(--white)/.8);
            padding: 15px 0;
            border-bottom: .5px solid hsl(var(--white)/.1);
            @include sm-screen {
                padding: 10px 0;
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}
/* =======================plan section css end here======================= */