/*============================ process section css start here ============================*/
.process {
    position: relative;
    z-index: 1;
 @extend .flex-between;
 gap: 24px;
 @include md-screen {
    justify-content: center;
    gap: 24px;
 }
 @include xxsm-screen {
    gap: 12px;
 }
}
.process-item {
    padding: 40px 20px;
    border-radius: 10px;
    -webkit-mask-image: url(../images/shapes/process1.png);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background-color: hsl(var(--section-bg)); 
    width: calc( 20% - 24px);
    gap: 24px;
    @include md-screen {
        width: calc( 33% - 24px);
        padding: 35px 18px;
    }
    @include sm-screen {
        width: calc( 33% - 24px);
        padding: 30px 15px;
    }
    @include msm-screen {
        width: calc(50% - 24px);
        padding: 20px 10px;
    }
    @include xsm-screen {
        padding: 15px 10px;
    }
    @include xxsm-screen {
        width: calc( 100% - 12px);
        padding: 20px 10px;
    }
    &:nth-child(even) {
        -webkit-mask-image: url(../images/shapes/process2.png);
    }
    &__thumb {
        text-align: center;
        margin: 15px 0;
        @include msm-screen {
            margin: 10px 0;
        }
    }
    &__title {
        text-align: center;
        font-weight: 700;
        margin-bottom: 0;
    }
}
/*============================= process section css end here============================= */