
/* ================================= Social Icon Css Start =========================== */
.social-list {
    display: flex;
    flex-wrap: wrap; 
    align-items: center;
    &__item {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        background-color: hsl(var(--white) / .1);
        border-radius: 50%;
        transition: .3s;
        cursor: pointer;
        color: hsl(var(--white));
        &.active {
            background: var(--base-gradient);
            color: hsl(var(--white)) !important;
            border-color: hsl(var(--base)) !important;
        }
        @include sm-screen {
            width: 35px;
            height: 35px;
            @include fs-14;
        }
        &:hover, &:focus {
            background-color: hsl(var(--base));
            color: hsl(var(--white));
            border-color: hsl(var(--base));
        }
    }
}
/* ================================= Social Icon Css End =========================== */