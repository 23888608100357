/* ================================= Table Css Start =========================== */
/* Table Css Start */
.table {
	margin: 0;
	@extend .fs-15; 
	border-collapse: collapse;
	border-collapse: separate;
	border-spacing: 0px 20px;
	position: relative;
	z-index: 9;
	&.style-two {
		border-spacing: 0px 0px;
		thead { 
			tr {
				th {
					text-align: left;
					font-size: 20px; 
					padding: 25px 30px;
					color: hsl(var(--white)); 
					font-family: var(--heading-font);
					font-weight: 700;
					border-bottom: 0;
					max-width: 170px;
					@include md-screen {
						font-size: 17px;
					}
					&:not(:first-child) {
						border-bottom: 0 !important;
					}
					&:first-child {
						text-align: left;
						border-radius: 6px 0 0 0px;
					}
					&:last-child {
						border-radius: 0 6px 0px 0; 
						text-align: right;
					}
					&:nth-child(2) {
						text-align: left;
					}
				}
			}
		}
		tbody {
			border: 0 !important;
			tr {
				border-bottom: 1px solid hsl(var(--white) / .08) !important;
				background-color: hsl(var(--section-bg));
				box-shadow: none;
				border-radius: 6px;
				transition: .2s linear;
				&:last-child {
					td {
						border-bottom: 0 !important;
						@include md-screen {
							border-bottom: 1px solid hsl(var(--white) / .08) !important;
						}
						&:last-child {
							border-radius: 0 0 6px 0;
						}
						&:first-child {
							border-radius: 0 0 0 6px;
						}
					}
				}
				@include md-screen {
					border-bottom: 0 !important;
				}
				&:hover {
					transform: translateY(0px);
				}
				td {
					text-align: left;
					vertical-align: middle;
					padding: 20px 30px;
					border-bottom: 1px solid hsl(var(--white) / .08);
					font-family: var(--body-font);
					color: hsl(var(--white)/0.8); 
					font-weight: 400;
					max-width: 170px;
					font-size: 20px !important; 
					@include xl-screen {
						font-size: 18px !important;
					}
					@include lg-screen {
						font-size: 15px !important;
					}
					&::before {
						content: attr(data-label);
						font-family: var(--heading-font);
						font-size: 20px; 
						color: hsl(var(--white)); 
						font-weight: 400;
						margin-right: auto;
						display: none;
						width: 50% !important;
						text-align: left;
					}
					&:first-child {
						text-align: left;
						@extend .fs-18; 
					}
					&:last-child {
						text-align: right;
					}
				}
			}
		}
	}
	thead { 
		tr {
			background: var(--base-gradient);
			th {
				text-align: left;
				font-size: 20px; 
				padding: 25px 30px;
				color: hsl(var(--white)); 
				font-family: var(--heading-font);
				font-weight: 700;
                border-bottom: 0;
				max-width: 170px;
				@include md-screen {
					font-size: 17px;
				}
				&:not(:first-child) {
					border-left: 0;
				}
				&:first-child {
					text-align: left;
					border-radius: 6px 0 0 6px;
				}
				&:last-child {
					border-radius: 0 6px 6px 0; 
					text-align: right;
				}
				&:nth-child(2) {
					text-align: left;
				}
			}
		}
	}
	tbody {
		border: 0 !important;
		tr {
			background-color: hsl(var(--section-bg));
			border-radius: 6px;
			transition: .2s linear;
			&:hover {
				transform: translateY(-5px);
			}
			&:last-child {
				border-bottom: 0;
			}
			td {
				text-align: left;
				vertical-align: middle;
				padding: 20px 30px;
                border: 0;
				font-family: var(--body-font);
				color: hsl(var(--white)/0.8); 
				font-weight: 400;
				max-width: 170px;
				font-size: 20px !important; 
				@include md-screen {
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid hsl(var(--white) / .08);
					align-items: center;
					font-size: 17px !important;
				}
				&::before {
					content: attr(data-label);
					font-family: var(--heading-font);
					font-size: 20px; 
					color: hsl(var(--white)); 
					font-weight: 400;
					margin-right: auto;
					display: none;
                    width: 50% !important;
                    text-align: left;
				}
				&:first-child {
					text-align: left;
					@extend .fs-18; 
				}
				&:last-child {
					text-align: right;
				}
			}
		}
	}
}

// .table--responsive--md {
//     @include sm-screen {
//         thead {
// 			display: none;
// 		}
// 		tbody {
// 			tr {
// 				display: block;
// 				&:last-child td {
// 					border-bottom: 0;
// 				}
// 				td {
// 					display: flex;
// 					align-items: center;
// 					justify-content: space-between;
// 					gap: 15px;
// 					text-align: right;
// 					padding: 10px 15px;
// 					border: none;
// 					border-bottom: 1px solid hsl(var(--black)/.8);  
// 					max-width: unset;
// 					&:last-child {
// 						border: none;
// 					}
// 					&:first-child {
// 						text-align: right;
// 						border-left: 0;
// 					}
// 					&::before {
// 					    display: block;
// 						@include fs-14; 
// 						color: hsl(var(--dark)/0.7); 
// 					}
// 				}
// 			}
// 		}
//     }
// 	@include sm-screen {
//         tbody {
// 			tr {
// 				td {
// 					border: 0;
// 				}
// 			}
// 		}
//     }
// }
.table--responsive--lg {
		@include md-screen {
			thead {
				display: none;
			}
			tbody {
				tr {
					display: block;
					border-spacing: 0px 20px;
					border-radius: 0px;
					&:first-child {
						border-radius: 6px 6px 0px 0px !important;
					}
					&:last-child {
						border-radius: 0 0 6px 6px !important;
					}
					 &:nth-child(odd) {
					background-color: hsl(var(--white) /.1);
	                }
					&:last-child td {
						&:last-child {
							border-bottom: 0 !important;
						}
					}
					
					&:hover {
						transform: translateY(-7px);
					}
					td {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 15px;
						text-align: right;
						padding: 10px 15px;
						border: none;
						max-width: unset;
						&:last-child {
							border: none;
						}
						&:first-child {
							text-align: right;
							border-left: 0;
						}
						&::before {
							display: block;
							font-size: 17px; 
						}
					}
				}
			}
		}
		@include md-screen {
			tbody {
				tr {
					td {
						border-bottom: 1px solid hsl(var(--white) / .08);
					}
				}
			}
		}
}
/* style- two table css */
.table--responsive--lg {
	&.style-two {
		@include md-screen {
			thead {
				display: none;
			}
			tbody {
				tr {
					display: block;
					border-spacing: 0px 20px;
					border-radius: 0px;
					&:first-child {
						border-radius: 6px 6px 0px 0px !important;
					}
					&:last-child {
						border-radius: 0 0 6px 6px !important;
					}
					 &:nth-child(odd) {
					background-color: hsl(var(--white) /.1);
	                }
					&:last-child td {
						&:last-child {
							border-bottom: 0 !important;
						}
					}
					
					&:hover {
						transform: translateY(-7px);
					}
					td {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 15px;
						text-align: right;
						padding: 10px 15px;
						border: none;
						max-width: unset;
						&:last-child {
							border: none;
						}
						&:first-child {
							text-align: right;
							border-left: 0;
						}
						&::before {
							display: block;
							font-size: 17px; 
						}
					}
				}
			}
		}
		@include md-screen {
			tbody {
				tr {
					td {
						border-bottom: 1px solid hsl(var(--white) / .08);
					}
				}
			}
		}
	}
}
/*================================= Table Css End =========================== */


