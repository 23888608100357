/* ================================= Custom Classes Css Start =========================== */
/* Column Extra Small Screen */
.col-xsm-6 {
    @media screen and (min-width:425px) and (max-width:575px) {
        width: 50%;
    }
}
// Custom Container
.custom--container {
    max-width: 1680px;
    margin: 0 auto;
    padding: 0 15px;
}


/* Section Background */
.section-bg {
    background-color: hsl(var(--section-bg));
}
// Full Screen Height Css
.full-display {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// Bg Image Css
.bg-img{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
}

/* Background Ovelray Css Start */
.bg-overlay{
    position: relative;
    isolation: isolate;
    &::before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        background-color: hsl(var(--base-two)); 
        opacity: .8;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
.bg-overlay-two{
    position: relative;
    isolation: isolate;
    &::before{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        background-color: hsl(var(--base)); 
        opacity: .8;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

/* Hide Scroll bar Css For Custom Modal */
body.scroll-hidden {
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: hidden;
    width: calc(100% - 8px);
}
body.scroll-hidden .header {
    right: 8px;
}
 body.scroll-hidden .header.fixed-header {
    padding-right: 8px;
}

// For Small Device
body.scroll-hidden-sm{
    @include md-screen {
        position: absolute;
        top: 0;
        left: 0;
        overflow-y: hidden;
        width: calc(100% - 0px);
    }
}
/* ================================= Custom Classes Css End =========================== */