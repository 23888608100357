/*============================ program css start here ============================*/
.program-section {
  position: relative;
  z-index: 1;
  &__shape {
    position: absolute;
    bottom: 10px;
    left: 200px;
    z-index: -1;
  }
}
/*===== program item css===== */
.program-item {
    padding: 30px;
    background-color: hsl(var(--section-bg));
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    margin-top: 20px;
    @include md-screen {
        padding: 25px;
    }
    @include msm-screen {
        padding: 15px;
    }
    &__title {
        margin-bottom: 5px;
        font-weight: 700;
    }
    &__desc {
        max-width: 500px;
        @extend .fs-16;
        color: hsl(var(--white)/.8);
    }
    &__icon {
        @extend .fs-17;
        color: hsl(var(--white));
        background: var(--background-gradient);
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;  
        border-radius: 50%;
        @include msm-screen {
           width: 40px;
           height: 40px;
           line-height: 40px;
        }
        i {
            line-height: 0;
        }
    }
    &__content {
        width: calc( 100% - 50px );
        padding-left: 20px;
    }
}
.program-thumb {
    img {
        width: 100%;
        height: 100%;
        @include md-screen {
            min-height: 400px;
        }
        @include msm-screen {
            min-height: 300px;
        }
    }
}
/*=========================== program section css end here ===========================*/