//============================================== account css start here ==============================================
.account {
    padding-top: 150px;
    padding-bottom: 60px;
    @include sm-screen {
        padding-top: 120px
    }
}
.account-wrapper {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        top: 5px;
        left: -30px;
        width: 100%;
        height: 90%;
        transform: rotate(183deg);
        background: var(--background-gradient);
        z-index: -1;
        border-radius: 40px;
        @include md-screen {
            display: none;
        }
      }
    &-inner {
      position: relative;
      background-color: hsl(var(--section-bg));
      border-radius: 40px;
      overflow: hidden;
      z-index: 1;
      @include md-screen {
        border-radius: 10px;
      }
    }
}


.account-form  {
    box-shadow: 0 6px 20px hsl(var(--black)/.07);
    // background-color: hsl(var(--white)/.1);
    padding: 50px;
    border-radius: 10px;
    overflow: hidden;
    @include lg-screen {
        padding: 40px 30px;
    }
    @include md-screen {
        padding: 35px 25px;
    }
    @include sm-screen {
        padding: 30px 20px;
    }
    @include xsm-screen {
        padding: 30px 15px;
    }
}

.account-thumb {
    &.style-two {
        @media (min-width:1199px) {
            top: 60px;
        }
    }
    img {
        @extend .fit-image; 
    }
    @media (min-width: 1199px) {     
        position: absolute;
        right: 160px;
        bottom: 50px;
        width: 35%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    @include xl-screen {
        right: 100px;
    }
    @include lg-screen {
        display: none;
    }
}
.account-shape-one {
    position: absolute;
    content: "";
    top: -270px;
    right: 500px;
    z-index: -1;
    width: 120px;
    animation: horizontal-move 3s ease-in-out infinite;
    animation-delay: 1s;
    @include xl-screen {
        right: 390px;
    }
    @include lg-screen {
        display: none;
    }
}
.account-shape-two {
    position: absolute;
    content: "";
    top: -80px;
    right: 60px;
    z-index: -1;
    width: 100px;
    animation: horizontal-move 3s ease-in-out infinite;
    animation-delay: 1s;
    @include lg-screen {
        display: none;
    }
}
.forgot-password {
    &:hover {
        color: hsl(var(--white));
    }
}
.term {
    &:hover {
        color: hsl(var(--white));
    }
}
/*=============================================== account css end here =============================================== */