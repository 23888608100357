
/* ====================== Section Heading ==================== */
.section-heading {
  margin-bottom: 40px;
  text-align: left;
  position: relative;
  z-index: 1;
  @include msm-screen {
    margin-bottom: 20px;
  }
  &__subtitle {
    margin-bottom: 15px;
    background: (var(--base-gradient));
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 100px;
    font-family: var(--heading-font);
    font-weight: 700;
    letter-spacing: 2px;
    color: hsl(var(--heading-color));
  }
  &__title {
    margin-bottom: 0;
    font-size: var(--heading-one);
  }
  &__desc {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
  // Style Two
  &.style-two {
    text-align: center;
    @include msm-screen {
      margin-bottom: 20px;
    }
  }
  // Style Three
  &.style-three {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    text-align: left;
    @include sm-screen {
      display: block;
    }
    .section-heading {
      &__title {
        margin-bottom: 0;
        padding-bottom: 0;
        margin-right: 50px;
        padding-right: 50px;
        flex-shrink: 0;
        position: relative;
          @include sm-screen {
            margin-bottom: 10px;
            margin-right: 0px;
            padding-right: 0px;
          }
          &::before {
            position: absolute;
            content: "";
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            width: 2px;
            height: 35px;
            background-color: hsl(var(--base));
            @include sm-screen {
              display: none;
            }
        }
      }
      &__desc {
        max-width: 100%;
        margin-left: 0;
      }
    }
  }
}
/* ====================== Section Heading En d==================== */

/*================= Slick Arrow & Dots css Start ================ */
.slick-initialized.slick-slider {
  margin: 0 -10px;
  .slick-track {
      display: flex;
  }
  .slick-slide {
      height: auto;
      padding: 0 10px;
      > div {
          height: 100%;
      }
  }
}

.slick-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  color: hsl(var(--white));;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: .2s linear; 
  background-color: hsl(var(--base));
  color: hsl(var(--white));;
  font-size: 20px;
  @include md-screen {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
  @include msm-screen {
    width: 45px;
    height: 45px;
  }
   &:hover {
    border-color: hsl(var(--base));
    background-color: hsl(var(--base-two));
  }
}
.slick-next {
  right: -20px;
  @include md-screen {
    right: -10px;
  }
  @include msm-screen {
    right: 10px;
  }
}
.slick-prev {
  left: -20px;
  @include md-screen {
    left: -10px;
  }
  @include msm-screen {
    left: 10px;
  }
}

/* -------------- Slick Slider Arrow Style two ------------ */
.slick-arrow {
  position: absolute;
  z-index: 1;
  border: none;
  background-color: transparent;
  color: hsl(var(--white));;
  width: 32px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: .4s;
  background-color:  hsl(var(--dark));
  color: hsl(var(--white));
  top: -67px;
  @include lg-screen {
    top: -69px;
  }
  @include sm-screen {
    top: auto; 
    bottom: -50px;
    right: 50%;
  }
}
.slick-arrow:hover {
  background-color: hsl(var(--dark));
  color: hsl(var(--white));;
}
.slick-next {
  right: 10px;
  background-color:  hsl(var(--base));
  @include sm-screen {
    top: auto; 
    right: calc(50% + -37px);

  } 
}
.slick-prev {
  right: 52px;
  @include sm-screen {
    top: auto; 
    right: calc(50% - -5px);
  }
}

/*  Dots Css Start */
.slick-dots {
    text-align: center;
    padding-top: 20px;
    li {
        display: inline-block;
        button {
            border: none;
            background-color: hsl(var(--dark));
            color: hsl(var(--white));;
            margin: 0 3px;
            width: 8px;
            height: 8px;
            border-radius: 1px;
            border-radius: 50%;
            text-indent: -9999px;
            transition: .3s linear;
        }
        &.slick-active button {
            background-color: hsl(var(--base));
            width: 25px;
            border-radius: 5px;
        }
    }
}
// Dots Css End
/*================= Slick Arrow & Dots css Start ================ */



