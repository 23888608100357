/*=========================== Accodion Css start ============================= */
.custom--accordion {
    .accordion-item {
        border: 1px solid hsl(var(--dark) / .09);
        background-color: transparent !important;
        border-radius: 5px;
        overflow: hidden;
        &:not(:last-child) {
            margin-bottom:10px;
        }
    }
    .accordion-body {
        padding: 0;
        background-color: transparent;
        .text {
            max-width: 100%;
            color: hsl(var(--white)/.8);
            @extend .fs-16;
            @include msm-screen {
                max-width: 100%;
            }
        }
    }
    &:first-of-type .accordion-button.collapsed {
        border-radius: 5px;  
    }
    &:last-of-type .accordion-button.collapsed {
        border-radius: 5px;  
    }
    .accordion-button{
        margin-bottom: 10px;
        background-color: hsl(var(--section-bg));
        color: var(--heading-color);
        @extend .fs-18; 
        padding: 20px 30px;
        border-radius: 5px;
        font-weight: 700;
        font-family: var(--heading-font);
        @include msm-screen {
            padding: 13px;
            padding-right: 30px;
        }
        &::after {
            background-image: none;
        }
        &:focus {
            box-shadow: none;
        }
        &:not(.collapsed) {
            color: hsl(var(--white)); 
            background-color: hsl(var(--section-bg)) !important;
            box-shadow: none;
            &::after {
                transform: rotate(0deg);
                background-image: none;
                color: hsl(var(--base)); ; 
            }
        }
        &[aria-expanded="true"], &[aria-expanded="false"] {
            &::after {
                font-family: "Line Awesome Free";
                font-weight: 700;
                content: "\f068";
                display: inline-block;
                position: relative;
                margin-left: auto;
                width: 0 !important;
                right: 15px;
                color: hsl(var(--white));
                @include msm-screen {
                    right: -13px;
                    // @extend .fs-14; 
                }
            }
        }
        &[aria-expanded="false"] {
            &::after {
                content: "\f067";
                color: hsl(var(--white));
            }
        }
    }
    
}
/* ================================= Accodion Css End =========================== */