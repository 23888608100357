
/* ================================= Card Css Start =========================== */
.custom--card {
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    background-color: hsl(var(--white));;
    border: transparent;
    .card-header {
        padding: 13px 20px;
        background-color: transparent;
        border-bottom: 1px solid hsl(var(--dark)/.1);
    }
    .card-body {
        background-color: hsl(var(--white));
        padding: 20px;
        border-radius: 5px;
        &__icon {
            font-size: 26px;
            color: hsl(var(--white));
        }
    }
    .card-footer {
        padding: 13px 20px;
        background-color: transparent;
        border-top: 1px solid hsl(var(--dark)/.1);
    }
}
/* ================================= Card Css End =========================== */

