
/* ============ Sidebar search box ============= */
.search-box {
    position: relative;
    &__button {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: hsl(var(--white)/.2); 
    }
}

/* ================== Sidebar Box & Title =================== */
.blog-sidebar {
    background-color: transparent; 
    padding: 0px 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 0;
    }
    @include msm-screen {
        padding: 20px 15px;
    }
    &__title {
        position: relative;
        padding-bottom: 10px;
        color: hsl(var(--white)); 
    }
}

/* ========================= Category & Tags List Style ========================= */
.text-list {
    // Category
    &.style-category {
        .text-list__item {
            border-bottom: 1px solid hsl(var(--white) / .2);
        }
        .text-list__link{
            color: hsl(var(--white)); 
            padding: 10px 0;
            &:hover {
                color: hsl(var(--base)); 
            }
        }
    }
    // Style Tag
    // &.style-tag {
    //     display: flex;
    //     flex-direction: row;
    //     flex-wrap: wrap;
    //     margin: -7px;
    //     .text-list__item {
    //        padding: 7px;
    //        display: block;
    //     }
    //     .text-list__link{
    //         color: hsl(var(--white)); 
    //         border: .5px solid hsl(var(--white) / .09); 
    //         padding: 10px 20px;
    //         border-radius: 3px;
    //         font-size: 16px;
    //         display: block;
    //         &.active {
    //             color: hsl(var(--white)); 
    //             background-color: hsl(var(--base)); 
    //             border-color: hsl(var(--base)); 
    //         }
    //         &:hover {
    //             color: hsl(var(--white)); 
    //             background-color: hsl(var(--base)); 
    //             border-color: hsl(var(--base)); 
    //         }
    //     }

    // }
}

/* ======================== Latest blog======================== */

