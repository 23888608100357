/* ================================= Button Css Start =========================== */
button {
    border: none;
    transition: .2s linear;
    &:focus {
      outline: none;
      box-shadow: none;
    }
}

.btn {
    &:focus {
        outline: none;
        box-shadow: none;
      }
      color: hsl(var(--white));
      font-weight: 500;
      padding: 10px 30px;
      font-size: 16px;
      border-radius: 4px;
      position: relative;
      z-index: 1;
      text-align: center;
      border: 1px solid transparent;
      font-weight: 600;
      @include sm-screen {
          padding: 11px 25px;
      }
      @include msm-screen {
          padding: 11px 20px;
      }
      &:hover, &:focus {
          color: hsl(var(--white));
          border-color: transparent;
      }
      &--lg {
        padding: 15px 35px;
        @include sm-screen {
            padding: 13px 30px;
        }
        @include msm-screen {
            padding: 11px 25px;
        }
      }
      &--sm {
        padding: 5px 20px;
        @extend .fs-15;
      }
      &--icon {
        width: 35px;
        height: 35px;
        line-height: 35px;
        @extend .fs-15; 
        padding: 0;
    }
    /* ============= Different Color Button Start ================== */
    &--base {
        background: var(--base-gradient);
        position: relative;
        z-index: 1;
        overflow: hidden;
        &::before {
            position: absolute;
            left: -100%;
            top: 0;
            height: 100%;
            width: 100%;
            background: var(--base-gradient);
            content: "";
            z-index: -1;
            border-radius: 3px;
            transition: .3s ease-in-out;
        }
        &:hover::before {
            left: 0%;
        }
        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0%;
            width: 100%;
            height: 100%;
            background: var(--base-gradient);
            transition: all ease 0.3s;
            z-index: -1;
        }
        &:hover::after {
            left: 100%;
        }
        // &:hover, &:focus {
        //     border: 1px solid hsl(var(--base));
        //     color: hsl(var(--white));
        // }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
         position: relative;
         border: 0;
         background: var(--base-gradient);
         z-index: 1;
         color: hsl(var(--white));
         &::after {
            position: absolute;
            content: "";
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            left: 2px;
            top: 2px;
            bottom: -2px;
            right: -2px;
            background: #141443 !important;
            z-index: -1;
            border-radius: inherit;
        }
         &:hover::after{
            background: transparent !important;

         }
         &::before {
            display: none;
         }
        }
    }
    &--simple {
        font-size: 16px;
        font-family: var(--body-font);
        font-weight: 500;
        color: hsl(var(--white));
        &__icon {
            @extend .fs-13; 
            margin-left: 5px;
        }
    }
    &--primary {
        background-color: hsl(var(--primary));
        &:hover, &:focus {
            background-color: hsl(var(--primary-d-100)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--primary));;
            background-color: transparent;
            color: hsl(var(--primary));
            &:hover, &:focus {
                background-color: hsl(var(--primary));;
                color: hsl(var(--white));
            }
        }
    }
    &--secondary {
        background-color: hsl(var(--secondary));
        &:hover, &:focus {
            background-color: hsl(var(--secondary-d-100)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--secondary));;
            background-color: transparent;
            color: hsl(var(--secondary));
            &:hover, &:focus {
                background-color: hsl(var(--secondary));;
                color: hsl(var(--white));
            }
        }
    }
    &--success {
        background-color: hsl(var(--success));
        &:hover, &:focus {
            background-color: hsl(var(--success-d-100)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--success));;
            background-color: transparent;
            color: hsl(var(--success));
            &:hover, &:focus {
                background-color: hsl(var(--success));;
                color: hsl(var(--white));
            }
        }
    }
    &--danger {
        background-color: hsl(var(--danger));
        &:hover, &:focus {
            background-color: hsl(var(--danger-d-100)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--danger));;
            background-color: transparent;
            color: hsl(var(--danger));
            &:hover, &:focus {
                background-color: hsl(var(--danger));;
                color: hsl(var(--white));
            }
        }
    }
    &--warning {
        background-color: hsl(var(--warning));
        &:hover, &:focus {
            background-color: hsl(var(--warning-d-100)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--warning));;
            background-color: transparent;
            color: hsl(var(--warning));
            &:hover, &:focus {
                background-color: hsl(var(--warning));;
                color: hsl(var(--white));
            }
        }
    }
    &--info {
        background-color: hsl(var(--info));
        &:hover, &:focus {
            background-color: hsl(var(--info-d-100)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--info));;
            background-color: transparent;
            color: hsl(var(--info));
            &:hover, &:focus {
                background-color: hsl(var(--info));;
                color: hsl(var(--white));
            }
        }
    }
    &--white {
        background-color: transparent;
        &:hover, &:focus {
            background-color: hsl(var(--white)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--white));
            background-color: transparent;
            color: hsl(var(--white));
            &:hover, &:focus {
                background-color: hsl(var(--white));;
                color: hsl(var(--base));
            }
        }
    }
    &--violet {
        background-color: hsl(var(--violet));
        &:hover, &:focus {
            background-color: hsl(var(--violet-d-100)); 
        }
        &.pill {
            border-radius: 35px;
        }
        &.outline {
            border: 1px solid hsl(var(--violet));;
            background-color: transparent;
            color: hsl(var(--violet));
            &:hover, &:focus {
                background-color: hsl(var(--violet));;
                color: hsl(var(--white));
            }
        }
    }
}
/* ================================= Button Css End =========================== */