/* ================================= Dashboard Fulid Sidebar Css Start =========================== */
.dashboard-fluid {
  // Sidebar logo Css Start
  .sidebar-logo {
    background-color: transparent;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    &__link {
      display: block;  
      width: 100%;
      padding: 50px 20px;
      img {
        max-width: 180px;
        max-height: 100px;
      }
    }
  }
  // Sidebar logo Css End
 /* =====balance css start here ===== */
  .balance {
    padding: 0 20px;
    &__title {
      margin-bottom: 10px;
    }
    &__item {
      background-color: hsl(var(--white)/0.09);
      padding: 10px 20px;
      border-radius: 5px;
      margin-bottom: 15px;
      &-wallet {
        @extend .fs-16;
        font-family: var(--body-font);
        font-weight: 400;
        color: hsl(var(--white)/.5);
      }
      &-number {
        margin-bottom: 0;
        font-weight: 700;
        font-family: var(--body-font);
      }
      &-currency {
        @extend .fs-16;
        font-weight: 600;
      }
    }
    &__button {
      margin-top: 30px;
      border-bottom: 1px solid hsl(var(--white)/.1);
      padding-bottom: 60px;
      &-one {
        background: var(--base-gradient);
        padding: 15px 35px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        font-family: var(--body-font);
        &:last-child {
          background-color: hsl(var(--white)/0.09) !important;
          background-image: none;
          margin-left: 20px;
        }
      }
    }
  }
 /* ====== balance css end here ====== */
  // Sidebar Menu Start
  .sidebar-menu {
    padding-bottom: 10px;
    height: 100vh;
    background-color: hsl(var(--body-background));
    overflow-y: auto;
    z-index: 999; 
    transition: .2s linear;
    padding-top: 0;
    width: 320px;
    border-radius: 0;
    position: sticky;
    top: 0;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
  }
    /*=========== Sidebar Show hide Css Start ========= */
    &.show-sidebar {
        transform: translateX(0);
    }
    @include lg-screen {
        position: fixed;
        left: 0;
        top: 0;
        min-width: 320px;
        transform: translateX(-100%);
        z-index: 9992;
        border-radius: 0;
        border-right: 1px solid hsl(var(--white)/0.1);
    }
    &__close {
        position: absolute;
        top: 12px;
        right: 12px;
        color: hsl(var(--white));
        border: 1px solid hsl(var(--white)/.7);
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 3px;
        @extend .fs-18; 
        transition: .2s linear;
        cursor: pointer;
        z-index: 9;
        &:hover, &:focus {
            background-color: hsl(var(--white));
            border-color: hsl(var(--white));
            color: hsl(var(--base));
        }

    }
  /* ========== Sidebar Show hide Css End =========*/
    &-list {
      padding: 0 20px;
        &__item {
          &.active {
            color: hsl(var(--white));
            background-image: var(--base-gradient);
            border-radius: 5px;
          }
          &.has-dropdown {
            &.active {
                > a {
                  color: hsl(var(--dark));
                  background-color: hsl(var(--white));
                  border-radius: 6px 6px 0 0;
                    &:after {
                        transform: rotate(90deg);
                        right: 2px;
                        color: hsl(var(--dark));
                    }
                }
            }
            > a {
                &:after {
                    font-family: "Line Awesome Free";
                    font-weight: 900;
                    content: "\f105";
                    font-style: normal;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    text-align: center;
                    background: 0 0;
                    position: absolute;
                    right: 0px;
                    top: 14px;
                    transition: .1s linear;
                    color: hsl(var(--white)/.6);
                }
            }
          }
        }
        &__link {
            display: inline-block;
            text-decoration: none;
            position: relative;
            padding: 15px 10px;
            width: 100%;
            color: hsl(var(--white)/.8);
            font-weight: 700;
            @extend .fs-16; 
            &.active {
              color: hsl(var(--base));
            }
            .icon{
                margin-right: 5px;
                @extend .fs-15; 
                text-align: center;
                border-radius: 4px;
                animation: swing ease-in-out 0.5s 1 alternate;
            }
        }
    }
  }
  // Sidebar Menu End
  // Sidebar Submenu Start
  .sidebar-submenu {
    display: none;
    &.open-submenu {
      display: block;
    }
    &-list {
        padding: 5px 0;
        &__item {
          &.active {
            > a {
              color: hsl(var(--base));
              background-color: hsl(var(--dark));
            }
          }
        }
        &__link {
          padding: 8px 0px;
          display: block;
          color: hsl(var(--dark));
          color: hsl(var(--white)/0.9);
          font-weight: 400;
          @extend .fs-15; 
          margin-left: 20px;
            &:before {
                content: "\f111";
                font-family: "Font Awesome 5 Free";
                font-weight: 400;
                font-style: normal;
                display: inline-block;
                text-align: center;
                text-decoration: none;
                margin-right: 10px;
                font-size: 0.5rem; //8px
            }
        }
    }
  }
  /* Sidebar Submenu End */
}
/* ================================= Dashboard Fulid Sidebar Css End =========================== */