/* ================================= Pagination Css Start =========================== */
.pagination {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
    @include lg-screen {
        margin-top: 50px;
    }
    @include md-screen {
        margin-top: 40px;
    }
    @include sm-screen {
        margin-top: 30px;
    }
    .page-item {
        .page-link{
            border: 1px solid hsl(var(--dark) / .08);
            margin: 0 5px;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: hsl(var(--section-bg));
            font-weight: 500;
            padding: 0;
            color: hsl(var(--white));
            &.active, &:hover {
                background: var(--background-gradient);
                color: hsl(var(--white)); ;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}
/* ================================= Pagination Css End =========================== */