// ============================ Media Breakpoint for Each Device Start ============================ 
//Mobile Landscape
@mixin landscape {
  @media all and (orientation:landscape) {
    @content;
  }
}

//Mobile Potrait
@mixin potrait {
  @media all and (orientation:potrait) {
    @content;
  }
}
  
//Extra Extra Small Screen
@mixin xxsm-screen {
  @media screen and (max-width: 374px) {
    @content;
  }
}

// Xtra Small
@mixin xsm-screen {
  @media screen and (max-width: 424px) {
    @content;
  }
}
// Medium Small Screen (max-width: 575)
@mixin msm-screen {
  @media screen and (max-width: 575px) {
    @content;
  }
}

//Small Screen (max-width: 767)
@mixin sm-screen {
  @media screen and (max-width: 767px) {
    @content;
  }
}

//Large Screen (max-width: 991)
@mixin md-screen {
  @media screen and (max-width: 991px) {
    @content;
  }
}

//Xtra Large Screen (max-width: 1199)
@mixin lg-screen {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

//Xtra Large Screen i (max-width: 1399)
@mixin xl-screen {
  @media screen and (max-width: 1399px) {
    @content;
  }
}

//Xtra Large Screen ii (max-width: 1499)
@mixin xxl-screen {
  @media screen and (max-width: 1499px) {
    @content;
  }
}
//Xtra Large Screen ii (max-width: 1599)
@mixin xxxl-screen {
  @media screen and (max-width: 1599px) {
    @content;
  }
}
/* ============================ Media Breakpoint for Each Device End ============================ */

/* =========================== Font Size For resposive devices Start =========================== */
@mixin fs-10 {
  font-size: 0.625rem;
}
@mixin fs-11 {
  font-size: 0.6875rem;
}
@mixin fs-12 {
  font-size: 0.75rem;
}
@mixin fs-13 {
  font-size: 0.8125rem;
}
@mixin fs-14 {
  font-size: 0.875rem;
}
@mixin fs-15 {
  font-size: 0.9375rem;
}
@mixin fs-16 {
  font-size: 1rem;
}
@mixin fs-17 {
  font-size: 1.0625rem;
}
@mixin fs-18 {
  font-size: 1.125rem;
}
@mixin fs-19 {
  font-size: 1.1875rem;
}
@mixin fs-20 {
  font-size: 1.25rem;
}
@mixin fs-21 {
  font-size: 1.3125rem;
}
@mixin fs-22 {
  font-size: 2.75rem;
}
@mixin fs-23 {
  font-size: 1.4375rem;
}
@mixin fs-24 {
  font-size: 1.5rem;
}
@mixin fs-25 {
  font-size: 1.5625rem;
}
@mixin fs-26 {
  font-size: 1.625rem;
}
@mixin fs-27 {
  font-size: 1.6875rem;
}
@mixin fs-28 {
  font-size: 1.75rem;
}
@mixin fs-29 {
  font-size: 1.8125rem;
}
@mixin fs-30 {
  font-size: 1.875rem;
}
/* ================================== Font Size For resposive devices End =============================== */