/* ================================= Tab Css Start =========================== */
.custom--tab {
    border-radius: 6px;
    margin-bottom: 50px;
    @include msm-screen {
        margin-bottom: 40px;
    }
    @include xsm-screen {
        margin-bottom: 20px;
    }
    .nav-item {
        border-bottom: 0;
        padding: 5px;
        .nav-link {
            color: hsl(var(--white)); 
            padding: 6px 15px !important;
            background-color: hsl(var(--section-bg)/.9) !important;
             border-radius: 5px;
            transition: .4s;
            @extend .fs-16;
            font-weight: 400;
            font-family: var(--body-font);
            border-bottom: 0 !important;
            // border: 1px solid hsl(var(--black) / .08) !important;
            @include lg-screen {
                padding: 4px 15px !important;
            }
            @include xsm-screen {
                padding: 4px 10px !important;
            }
            &.active {
                color: hsl(var(--white)); 
                background: var(--base-gradient)!important;
                // border: 1px solid transparent !important;
                &:hover {
                    color: hsl(var(--white)); 
                }
            }
            &:hover {
                color: hsl(var(--white));
            }
        }
    }
}
/* ================================= Tab Css End =========================== */
