/* =========================== Banner Section Start Here =========================*/
.banner-section {
    position: relative;
    background: var(--banner-gradient);
    z-index: 1;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    @include lg-screen {
        padding-top: 50px;
    }
    @include md-screen {
        padding-top:25px;
    }
    @include sm-screen  {
        padding-top: 180px;
    }
    @include msm-screen {
        padding-top: 160px;
    }
    &.bg-img {
        @include md-screen {
            background-position: center right;
            object-fit: contain;
        }
    }

}
.planet-bg {
    position: absolute;
    left: 190px;
    top: 220px;
    z-index: -1;
}
.planet-small {
    position: absolute;
    right: 40px;
    top: 280px;
    z-index: -1;
}
.banner-content {
    position: relative;
    z-index: 9;
    @include sm-screen {
        text-align: center;
    }
    &__title {
        font-size: 50px;
        margin-bottom: 0;
        @include md-screen {
            font-size: 45px;
        }
        @include sm-screen {
            font-size: 35px;
        }
        @include msm-screen {
            font-size: 30px;
        }
    }
    &__desc {
        max-width: 600px;
        color: hsl(var(--white) / .8);
    }
    &__buttons {
        padding-top: 50px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        @include md-screen {
            padding-top: 30px;
        }
        @include sm-screen {
            justify-content: center;
            align-items: center;
        }
    }
}
/* ====city scene css start here==== */

.city-scene {
    position: relative;
    width: 856px;
    height: 800px;
    z-index: 1;
    @include lg-screen {
        height: 600px;
    }
    @include md-screen {
        height: 475px;
    }
    @include sm-screen {
        height: 195px;
        opacity: 0.5;
    }
    @include msm-screen {
        height: 130px;
    }
}
.bd-1 {
    position: absolute;
    left: 90px;
    bottom: 0px;
    z-index: 40;
    animation: building-move 1.7s ease;
}
.bd-2 {
    position: absolute;
    left: 140px;
    bottom: 0px;
    z-index: 41;
    animation: building-move 1.6s ease;
}
.bd-3 {
    position: absolute;
    left: 370px;
    bottom: 0px;
    z-index: 43;
    animation: building-move 1.8s ease;
}
.bd-4{
    position: absolute;
    left: 467px;
    bottom: 0px;
    z-index: 44;
    animation: building-move 1.5s ease;
}
.bd-5 {
    position: absolute;
    right: 250px;
    bottom: 0px;
    z-index: 50;
    animation: building-move 1.8s ease;
}
.bd-6 {
    position: absolute;
    right: 150px;
    bottom: 0px;
    z-index: 51;
    animation: building-move 1.6s ease;
}
.bd-7 {
    position: absolute;
    right: 20px;
    bottom: 0px;
    z-index: 52;
    animation: building-move 1.5s ease;
}
.bd-8 {
    position: absolute;
    left: 135px;
    bottom: 102px;
    z-index: 30;
    animation: building-move 1.8s ease;
}
.bd-9 {
    position: absolute;
    left: 424px;
    bottom: 0px;
    z-index: 42;
    animation: building-move 1.85s ease;
}
.bd-10 {
    position: absolute;
    right: 110px;
    bottom: 85px;
    z-index: 30;
    animation: building-move 1.5s ease;
}
.bd-11 {
    position: absolute;
    right: 215px;
    bottom: 95px;
    z-index: 29;
    animation: building-move 2.1s ease;
}

.bd-12 {
    position: absolute;
    left: 180px;
    bottom: 140px;
    z-index: 20;
    animation: building-move 2.3s ease;
}
.bd-13 {
    position: absolute;
    right: 95px;
    bottom: 180px;
    z-index: 19;
    animation: building-move 2.4s ease;
}
.bd-14 {
    position: absolute;
    right: 80px;
    bottom: 100px;
    z-index: 18;
    animation: building-move 2.6s ease;
}
.bd-15 {
    position: absolute;
    right: 175px;
    bottom: 255px;
    z-index: 10;
    animation: building-move 2.55s ease;
}
.bd-16 {
    position: absolute;
    left: 485px;
    bottom: 260px;
    z-index: 18;
    animation: building-move 4s ease;
}
.bd-17 {
    position: absolute;
    right: 300px;
    bottom: 160px;
    z-index: 9;
    animation: building-move 3.1s ease;
}
.bd-18 {
    position: absolute;
    right: 358px;
    bottom: 200px;
    z-index: 8;
    animation: building-move 3.15s ease;
}
.bd-19 {
    position: absolute;
    right: 485px;
    bottom: 185px;
    z-index: 7;
    animation: building-move 3.3s ease;
}
.bd-20 {
    position: absolute;
    left: 395px;
    bottom: 285px;
    z-index: 7;
    animation: building-move 3.2s ease;
}
.bd-21 {
    position: absolute;
    left: 290px;
    bottom: 255px;
    z-index: 6;
    animation: building-move 3.1s ease;
}
.bd-22 {
    position: absolute;
    left: 140px;
    bottom: 140px;
    z-index: 1;
    animation: building-move 3.6s ease;
}

/* =====city scene end here======== */
/* =========================== Banner Section End Here ========================= */
/* ===========================train section start here=========================== */
.train-section {
    width: 100%;
    height: 75px;
    position: relative;
    z-index: 555;
    margin-top: -70px;
    overflow: hidden;
}
.train-wrapper {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 100;
    transform: translateX(1200px);
    animation: train-move 12s linear infinite;
    animation-delay: 1s;
}
.train{
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 160px;
        height: 28px;
        bottom: -2px;
        left: -158px;
        background: url(../../assets//images/shapes/train-light.png) no-repeat center;
        background-size: 160px;
        animation: 0.5s train-light ease-in-out infinite; 
    }
}
.railway {
    width: 100%;
    height: 5px;
    background: var(--railway-gradient);
    position: absolute;
    left: 0px;
    bottom: 0px;
}
@keyframes building-move {
    0% {
      transform: translateY(740px);
    }
  
    100% {
      transform: none;
    }
  }

  @keyframes train-move {
    0% {
      transform: translateX(1200px);
    }
  
    100% {
      transform: translateX(-5000px);
    }
  }
/* ===========================train section end here=========================== */