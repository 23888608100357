
/* ====================== Breadcumb Css Start ==================== */
.breadcumb {
    position: relative;
    z-index: 1;
    width: 100%;
    
    &__wrapper {
        text-align: left;
    }
    &__title {
        margin-bottom: 10px;
        color: hsl(var(--white)); 
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
    }
    &__item{
        color: hsl(var(--white)); 
        padding-right: 5px;
        font-weight: 400;
        @extend .fs-16; 
        &:last-child {
            padding-right: 0;
        }
        &-text {
            color: hsl(var(--white)); ;
        }
    }
    &__link {
        color: hsl(var(--white));  
        font-weight: 500;
        &:hover {
            color: hsl(var(--base)); ;
        }
    }
    &__inner {
       padding-top: 180px;
       padding-bottom: 170px;
        position: relative;
        @include md-screen {
            padding-top: 110px;
            padding-bottom: 100px;

        }
        @include sm-screen {
            background-image: none;
            padding-top: 115px;
            padding-bottom: 70px;
            background-color: hsl(var(--section-bg));

        }
    }
    &__bg {
        background: var(--base-gradient);
        width: 100%;
        height: 100%;
        -webkit-mask-image: url(../images/shapes/bd-3.png);
        mask-image: url(../images/shapes/bd-3.png);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-size: cover;
        mask-size: cover;
        position: absolute;
        top: 20px;
        left: 0;
        z-index: -1;
        @include sm-screen {
           display: none;
        }
    }
}
/* ====================== Breadcumb Css End ==================== */
