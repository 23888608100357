/* ============================== About Section Css Start ===================*/
.about-section {
    overflow: hidden;
}
.about-thumb {
    // height: 100%;
    img {
       @extend .fit-image;
    }
}
.about-content{
    &__desc {
        font-size: 20px;
        color: hsl(var(--white)/.8);
        margin-bottom: 30px;
        @include msm-screen {
            font-size: 18px;
        }
        @include xsm-screen {
            font-size: 16px;
        }
    }
    &__item {
        @extend .fs-18;
        display: flex;
        color: hsl(var(--white)/.8);
        margin-bottom: 15px;
        font-weight: 400;
        justify-content: center;
    }
    &__icon {
        padding-right: 26px;
        font-weight: 400;
        color: hsl(var(--white));
        line-height: 2;
        // background: var(--base-gradient);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }
}

/* ============================== About Section Css End ===================*/