/*=================== Fully Fit image Css ===================*/
.fit-image {
    width: 100%;
    height: 100%;
    object-fit: cover;  
}
/* ============================= Display Flex Css Start ============================= */
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-align {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .flex-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .flex-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
/* ============================= Display Flex Css End ============================= */

/* ===================== Font Size For resposive devices Start =================== */
.fs-10 {
    font-size: 0.625rem;
}
.fs-11 {
    font-size: 0.6875rem;
}
.fs-12 {
    font-size: 0.75rem;
}
.fs-13 {
    font-size: 0.8125rem;
}
.fs-14 {
    font-size: 0.875rem;
}
.fs-15 {
    font-size: 0.9375rem;
}
.fs-16 {
    font-size: 1rem;
    @include msm-screen {
        @include fs-15; 
    }
}
.fs-17 {
    font-size: 1.0625rem;
    @include md-screen {
        font-size: 1rem;
    }
    @include msm-screen {
        @include fs-15; 
    }
}
.fs-18 {
    font-size: 1.125rem;
    @include md-screen {
        @include fs-16; 
    }
    @include msm-screen {
        @include fs-15; 
    }
}
/* ===================== Font Size For resposive devices End =================== */


/* ============================= Positioning Css Class Start ===================== */
.pa {
    position: absolute;
    content: "";
}
.pa-wh-100 {
    position: absolute;
    width: 100%;  
    height: 100%;
}
.pa-tl-0 {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
}
.pa-wh-100-tl-0 {
    position: absolute;
    content: "";
    width: 100%;  
    height: 100%;
    top: 0;
    left: 0;
}
/* ============================= Positioning Css Class End ===================== */
