/* ============================================== policy section css start here ============================================== */

.policy-left {
        position: sticky;
        top: 100px;
        &__item {
            padding: 10px !important;
            font-weight: 500;
            font-size: 16px;
            color: hsl(var(--white)) !important;
            &.active {
                color: hsl(var(--black)) !important;
                background-color: hsl(var(--white)) !important;
            }
            &:last-of-type {
                border-bottom: 0 !important;
            }
        }
        &__wrapper {
            @include sm-screen {
                margin-bottom: 30px;
            }
        }
        &__list {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 700;
            color: hsl(var(--white));
            &.active {
                background-color: hsl(var(--white)) !important;
                color: hsl(var(--black)) !important;
            }
        }
}
.policy-card { 
    margin-bottom: 50px;
    &__desc {
       margin-bottom: 30px; 
       color: hsl(var(--white)/0.8);
       &:last-of-type {
        margin-bottom: 0;
       }
    }
}


/*=============================================== policy section css end here =============================================== */