/* =======================faq-section css start here======================= */
.faq-section {
    position: relative;
    z-index: 1;
    &__shape-one {
        position: absolute;
        left: 0;
        top: 100px;
        z-index: -1;
    }
    &__shape-two {
        position: absolute;
        right: 115px;
        top: 150px;
        z-index: -1;
    }
}
/* ========================faq section css end here========================*/