
/* ============= Header Start Here ======================= */
.navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    &.logo{
        @include md-screen {
            order: -1;
        }
        img {
            height: 100%;
            width: 100%;
            max-width: 200px;
            max-height: 50px;
            @include lg-screen {
                max-width: 150px;
            }
            @include md-screen {
                max-width: 130px;
            }
            @include msm-screen {
                max-width: 120px;
            }
        }
    }
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: transparent;
    @include md-screen {
        top: 0px;
        background-color: hsl(var(--black));
        padding: 10px 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 999;
        max-height: 101vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0px;
        }
    }
    &.fixed-header {
        background-color: hsl(var(--black));
        position: sticky;
        transition: .3s linear;
        top: 0px;
        animation: slide-down .8s;
        width: 100%;
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY( -150%);
    } 
    100% {
        opacity: 1;
        transform: translateY(0);
    } 
}

.navbar {
    padding: 0 !important;
}
/* ========================= Desktop Device Start ========================= */
@media (min-width: 992px) {
.nav-menu {
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    // margin: 0 -25px;
    .nav-item{
        position: relative;
        padding: 0 16px;
        @include xxxl-screen {
            padding: 0 10px;
        }
        @include lg-screen {
            padding: 0 7px;
        }

        &:hover {
            .nav-link {
                color: hsl(var(--white)) !important;
                &::before {
                    width: 100%;
                }
                .nav-item__icon {
                    transform: rotate(180deg);
                    transition: .2s;
                }
            }
        }
        .nav-link{
            font-weight: 700;
            @include fs-18; 
            color: hsl(var(--white)) !important;
            padding: 33px 0;
            position: relative;
            cursor: pointer;
            &.active {
                color: hsl(var(--white)) !important;
                &::before {
                    width: 100%;
                }
            }
            // ======================== Style two ================
            &:hover::before {
                left: 0;
                transition: .3s;
            }
            &::before {
                position: absolute;
                content: "";
                right: 0;
                bottom: 25px;
                width: 0;
                height: 2px;
                background-color: hsl(var(--white)); 
                // transform: translateX(-50%);
                transition: .3s;
            }
            .nav-item__icon{
                transition: .3s;
                @include fs-13; 
                margin-left: 2px;
                @include md-screen {
                    margin-right: 6px;
                }
            }
        }
    }
}
.dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    top: 100%;
    left: 0;
    padding: 0 !important;
    transform: scaleY(0);
    transform-origin: top center;
    transition: .3s;
    overflow: hidden;
    border-radius: 0;
    min-width: 190px;
    &__list {
        border-bottom: 1px solid hsl(var(--black) / .08);
        &:last-child {
            border-bottom: 3px solid hsl(var(--base)); 
        }
    }
    &__link{
        padding: 7px 20px;
        font-weight: 700;
        @include fs-18; 
        transition: .3s;
        &:focus, &:hover {
            color: hsl(var(--white));
            background-color: hsl(var(--base)); 

        } 
    }
}
.nav-menu {
    .nav-item {
        &:hover { 
            .dropdown-menu{
                visibility: visible; 
                opacity: 1;
                top: 100% !important;
                transform: scaleY(1);
            }
        }
    }
}
}

.language-select {
    padding: 33px 0;
}
.language-box {
    width:  calc( 100% - 40px) !important;
    padding-left: 10px !important;
    .select {
        color: hsl(var(--white)) !important;
        background: var(--base-gradient);
        border-radius: 5px;
        position: relative;
        padding: 7px 5px;
        &:focus {
            // border-color: hsl(var(--base));
            border: 0;
        }
        option {
            background-color: hsl(var(--dark));
            color: hsl(var(--white));
        }
    }
}
.global {
    &__icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: var(--base-gradient);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
/* ========================== Desktop Device End ========================= */

/* ============================== Small Device ======================= */
@include md-screen {
    .body-overlay.show {
        visibility: visible;
        opacity: 1;
    }
    .nav-menu {
        margin-top: 20px;
        .nav-item {
            padding: 0 12px;
            text-align: left;
            display: block;
            position: relative;
            margin: 0;
            &:hover .nav-link .nav-item__icon {
                transform: rotate(0deg) !important;
            }
        }
    }
    .nav-item {
        &:first-child{
            border-bottom: none;
        }
        &:last-child > a{
            border-bottom: 0;
        }
        .nav-link {
            margin-bottom: 8px;
            padding: 10px 10px 10px 0 !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 !important;
            border-bottom: 1px solid hsl(var(--white) / .2);
            color: hsl(var(--white));
            &::before{
                display: none;
            }
            &.show {
                &[aria-expanded="true"] {
                    color: hsl(var(--white)) !important;
                    i{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .dropdown-menu {
        border-radius: 3px;
        -webkit-box-shadow: none;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 2px;
        width: 100%;
        margin: 0px !important;
        padding: 0 !important;
        border: 0;
        background-color: inherit;
        overflow: hidden;
        li{
              &:nth-last-child(1){
                border-bottom: none; 
            }
            .dropdown-item{
                padding: 10px 0px;
                font-weight: 500;
                @include fs-16; 
                color: hsl(var(--white));
                border-bottom: 1px solid hsl(var(--white) / .2);
                margin-left: 20px;
                color: hsl(var(--white));
                &:hover, &:focus {
                    background-color: transparent;
                }
            }
        }
    } 
}

.navbar-toggler {
    &.header-button {
        border-color: transparent;
        color: hsl(var(--white));
        background: transparent !important;
        padding: 0 !important;
        border: 0 !important;
        border-radius: 0 !important; 
        transition: .15s ease-in-out;
        width: auto;
        &:focus {
        box-shadow: none !important;
        }
        &[aria-expanded="true"] i::before{
            content: "\f00d";
        }
        i {
            font-size: 1.5625rem; // 25px
        }
    }
}
// Login Registration
.login-registration-list {
    margin-left: 10px;
    @include md-screen {
        padding-right: 0;
        padding-left: 0;
        margin-left: 0;
    }
    &__item {
        color: hsl(var(--white));
        margin-left: 10px;
    }
    &__icon {
        color: hsl(var(--base));
        margin-right: 10px;
        @extend .fs-16; 
    }
   &__link {
        color: hsl(var(--white));
        font-size: 16px;
        font-weight: 600;
        font-family: var(--heading-font);
        @include msm-screen {
            @include fs-15; 
        }
    }
}

// ================ Top Header End Here ================
/* ================================= Header Css End =========================== */
