
/* ============= Footer Start Here ======================= */
.footer-area {
    z-index: 1;
    &-inner {
        position: relative;
        margin-top: 70px;
        padding-top: 100px;
        overflow: hidden;
        @include sm-screen {
            margin-top: 0;
            padding-top: 30px;
            background-image: none !important;
            background-color: hsl(var(--section-bg));
        }
    }
    &__bg {
        background: var(--base-gradient);
        width: 100%;
        height: 100%;
        -webkit-mask-image: url(../images/shapes/footer-shape02.png);
        mask-image: url(../images/shapes/footer-shape02.png);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-size: cover;
        mask-size: cover;
        position: absolute;
        top: 0;
        left: 50%;  
        z-index: -1;
        transform: translateX(-50%);
        @include sm-screen {
            display: none;
        }
      
    }
    
}
// @media (max-width:1799px) {
//     .footer-area {
//         &__shape {
//             img {
//                 width: 300px;
//             }
//         }
//     }
// }
.footer-shape {
    position: absolute;
    bottom: 0;
    left: 0;
}
@media (max-width:1799px) {
    .footer-shape {
        display: none;
    }
}
.footer-item {
    &__logo {
        margin-bottom: 20px;
        a {
            img {
                width: 100%;
                height: 100%;
                max-width: 160px;
                max-height: 64px;
            }
        }
    }
    &__title {
        color: hsl(var(--white));
        padding-bottom: 10px;
        margin-bottom: 25px;
        font-size: 18px;
        font-weight: 600;
        font-family: var(--body-font);

    }
    .social-list {
        margin-top: 30px;
        @include md-screen {
            margin-top: 20px;
        }
        @include msm-screen {
            margin-top: 15px;
        }
    }
}
// Footer List Item
.footer-menu {
    display: flex;
    flex-direction: column;
    margin: -5px 0;
    &__item {
        display: block;
        padding: 5px 0;
    }
    &__link {
        font-size: 16px;
        font-family: var(--body-font);
        font-weight: 400;
        color: hsl(var(--white) / .8);
        &:hover {
            color: hsl(var(--base));
            margin-left: 4px;
        }
    }
}
// Footer Contact
.footer-contact-menu {
    margin: -6px 0;
    &__item {
        display: flex;
        padding: 6px 0;
        &-icon {
            width: 15px;
            color: hsl(var(--base-two));
            font-size: 1rem; // 20px
        }
        &-content {
            width: calc(100% - 15px);
            padding-left: 15px;
        }
    }
}
/* ============= Footer End Here ======================= */

/* ============= Bottom Footer End Here =======================*/
.bottom-footer {
    // background-color: hsl(var(--dark));
    border-top: 1px solid hsl(var(--white) / .1);
    &-text {
        color: hsl(var(--white) / .8);
        font-weight: 400;
        font-family: var(--body-font);
        font-size: 16px;
    }
    &__left {
        @include xsm-screen {
            @include fs-15;
        }
        a {
            color: hsl(var(--base));
            font-weight: 600;
            padding: 0 5px;
           
            &:hover {
                color: hsl(var(--white));
            }
        }
    }
}
/* =============Bottom Footer End Here ======================= */

