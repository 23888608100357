
/* ================= Floting Input Css Start ================= */
// .input--floating {
// 	position: relative;
// 	a {
// 		@extend .fs-15; 
// 		font-weight: 500;
// 		&:hover {
// 			color: hsl(var(--white));
// 		}
// 	}

// 	.form--control {
// 		height: 40px;
// 		background-color: transparent;
// 		border: 1px solid hsl(var(--base));
// 		color: hsl(var(--white));
// 		@extend .fs-14; 
// 		padding-left: 15px;
// 		width: 100%;
// 		&:focus {
// 			outline: none;
// 			box-shadow: none;
// 			background-color: transparent;
// 			border: 1px solid hsl(var(--base));
// 			outline: none;
// 			border: 1px solid hsl(var(--base));
// 			~ {
// 				.form--label {
// 					top: 0;
// 					left: 15px;
// 					z-index: 5;
// 					@extend .fs-12; 
// 					font-weight: 500;
// 					color: hsl(var(--white));
// 					transition: all 0.2s ease-in-out;
// 				}
// 			}
// 		}
// 		&::placeholder {
// 			opacity: 0;
// 			visibility: hidden;
// 			color: transparent;
// 			opacity: 0;
// 			visibility: hidden;
// 			color: transparent;
// 			@extend .fs-13; 
// 		}
// 		&:not(:placeholder-shown).form--control {
// 			&:not(:focus) {
// 				~ {
// 					.form--label {
// 						top: 0;
// 						left: 15px;
// 						z-index: 9;
// 						transition: all 0.2s ease-in-out;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	.form--label {
// 		position: absolute;
// 		top: 20px;
// 		left: 15px;
// 		cursor: text;
// 		transform: translateY(-50%);
// 		color: hsl(var(--base-two));
// 		padding: 4px 4px;
// 		@extend .fs-12; 
// 		border-radius: 5px;
// 		transition: 0.2s ease;
// 		line-height: 0.8;
// 		pointer-events: none;
// 		z-index: 1;
// 		background-color: transparent;
// 		&::before {
// 			position: absolute;
// 			content: "";
// 			width: 100%;
// 			height: 2px;
// 			left: 0;
// 			top: 53%;
// 			background-color: hsl(var(--base-two));
// 			transform: translateY(-50%);
// 			z-index: -1;
// 		}
// 	}
// }

// select.form-control.form--control:has(option) ~ .form--label {
//     top: 0% !important;
// }

/* ================= Floting Input Css End ================= */
.form {
	&-group {
		position: relative;
		width: 100%;
		height: 3rem;
		margin-bottom: 1.5rem;
	}

	&-label {
		position: absolute;
		font-family: inherit;
		font-size: 1rem;
		font-weight: 400;
		line-height: inherit;
		left: 16px;
		top: 13px;
		padding: 0 0.25rem;
		color:hsl(var(--white)/.8);
		background: transparent;
		transition: all 0.3s ease;
		margin-bottom: 0 !important;
	}

	&-input {
		position: absolute;
		font-family: inherit;
		font-size: 1rem;
		font-weight: 400;
		line-height: inherit;
		top: 0;
		left: 0;
		z-index: 1;
		resize: none;
		width: 100%;
		height: auto;
		padding: 0.75rem 1.25rem;
		border-radius: 0.25rem;
		border: 1px solid hsl(var(--white)/.1);
		color: hsl(var(--white)/.8);
		background: transparent;
		transition: all 0.2s ease-in-out;

		&::placeholder {
			opacity: 0;
			visibility: hidden;
			color: transparent;
		}

		&:focus {
			outline: none;
			border: 1px solid hsl(var(--base));

			& ~ .form-label {
				top: -0.75rem;
				left: 1rem;
				z-index: 5;
				font-size: 0.875rem;
				font-weight: 500;
				color:hsl(var(--white));
				transition: all 0.2s ease-in-out;
			}
		}

		&:not(:placeholder-shown).form-input {
			&:not(:focus) {
				& ~ .form-label {
					top: -0.75rem;
					left: 1rem;
					z-index: 9;
					font-size: 0.875rem;
					font-weight: 500;
					transition: all 0.2s ease-in-out;
				}
			}
		}
	}
}

