/* ================================= Blog Section Css Start Here ================================= */
.blog-item {
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    &:hover {
        .blog-item {
            &__thumb {
                img {
                    transform: scale(1.2);
                }
            }
            &__title {
                &-link {
                    color: hsl(var(--base));
                }
            }
        }
    }
    &__thumb {
        overflow: hidden;
        border-radius: 8px;
        max-height: 300px;
        &-link {
            width: 100%;
            height: 100%;
            img {
                @extend .fit-image; 
                transition: .3s linear;
            }
        }
    }
    &__content {
        background-color: transparent;
        padding: 35px 0px;
        height: 100%;
        @include sm-screen {
            padding: 30px 5px;
        }
        @include xsm-screen {
            padding: 25px 0px;
        }
    }
    &__title {
        margin-top: 10px;
        &-link {
            overflow:hidden;
            text-overflow:ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &__desc {
        margin-bottom: 25px;
        overflow:hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @include sm-screen {
            margin-bottom: 15px;
        }
    }
}
.content-list {
    margin: 0 -20px;
    position: relative;
    display: inline-block;
    @include sm-screen {
        margin: 0 -15px;
    }
    &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 48%;
        transform: translateY(-50%);
        width: 2px;
        height: 15px;
        background-color: hsl(var(--white)/.2);
    }
    &__item {
        display: inline-block;
        padding: 0 20px;
        color: hsl(var(--white)/.5);
        font-size: 14px;
        font-weight: 500;
        font-family: var(--body-font);
        letter-spacing: 1px;
        @include sm-screen {
            padding: 0 14px;
            font-size: 13px;
        }
        @include msm-screen {
            font-size: 14px;
        }
    }
}
/* ================================= Blog Section Css End Here ================================= */